import Emoji from '@/components/Emoji';
import { IconProps } from '@/components/icons';
import LinkWrapper from '@/components/LinkWrapper';
import Section from '@/components/Section';
import ServiceCard from '@/components/ServiceCard';
import { useAuth } from '@/lib/auth';
import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { SVGProps } from 'react';
import { ConnectSection } from './connect';
import { FindSection } from './find';
import { SellSection } from './sell';
import { ToolsSection } from './tools';
import { useRouter } from 'next/router';
import { ReportsSection } from './reports';

const Home: NextPage = () => {
    const auth = useAuth();
    const { query } = useRouter();
    console.log(query.checkout);

    return (
        <div>
            <Head>
                <title>Virra Dashboard</title>
                <meta
                    name="description"
                    content="Welcome to the Virra Dashboard"
                />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main>
                {query.checkout === 'success' && (
                    <Section>
                        <div className="rounded-lg border-2 border-gray-100 p-4 text-lg">
                            <p className="font-bold">
                                <Emoji symbol="👋&nbsp;&nbsp;" />
                                Congratulations!
                            </p>
                            <p>Your purchase has been completed.</p>
                        </div>
                    </Section>
                )}
                {!auth.user.isAdmin && query.checkout !== 'success' && (
                    <Section>
                        <div className="rounded-lg border-2 border-gray-100 p-4 text-lg">
                            <p className="font-bold">
                                <Emoji symbol="👋&nbsp;&nbsp;" />
                                Heyo, welcome to Virra!
                            </p>
                            <p>
                                Glad to have you here. Check out our software
                                products below and take a look around!
                            </p>
                            <br />
                            <p>- Lukas</p>
                        </div>
                    </Section>
                )}
                <ConnectSection />
                <SellSection />
                {/* <FindSection /> */}
                {auth.user.isAdmin && <ToolsSection />}
                {auth.user.isAdmin && <ReportsSection />}
            </main>
        </div>
    );
};

export default Home;
