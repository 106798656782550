import { SVGProps } from 'react';
import { IconProps } from './icons';

// This component is to solve a warning that occurs by
// treating our Icon SVG wrapper component and regular SVGs (hero icon SVGs) the same.

export default function SVGIcon(
    SVGIcon: (props: React.ComponentProps<'svg'>) => JSX.Element
) {
    return function Icon(props: any) {
        const { colored, ...SVGProps } = props;
        return <SVGIcon {...SVGProps} />;
    };
}
